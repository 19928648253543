<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        d="M24 8C15.1795 8 8 15.1795 8 24C8 32.8205 15.1795 40 24 40C32.8205 40 40 32.8205 40 24C40 15.1795 32.8205 8 24 8ZM24 37.5385C16.5333 37.5385 10.4615 31.4667 10.4615 24C10.4615 16.5333 16.5333 10.4615 24 10.4615C31.4667 10.4615 37.5385 16.5333 37.5385 24C37.5385 31.4667 31.4667 37.5385 24 37.5385Z"
        fill="inherit"/>
    <path
        d="M29.7847 18.2152C29.3129 17.7434 28.5334 17.7434 28.0411 18.2152L24 22.2562L19.9385 18.2152C19.4667 17.7434 18.6872 17.7434 18.1949 18.2152C17.7231 18.687 17.7231 19.4665 18.1949 19.9588L22.2564 23.9998L18.1949 28.0613C17.7231 28.5331 17.7231 29.3126 18.1949 29.8049C18.4411 30.0511 18.7488 30.1741 19.0565 30.1741C19.3641 30.1741 19.6923 30.0511 19.918 29.8049L24 25.7434L28.0616 29.8049C28.3077 30.0511 28.6154 30.1741 28.9231 30.1741C29.2308 30.1741 29.559 30.0511 29.7847 29.8049C30.2564 29.3331 30.2564 28.5536 29.7847 28.0613L25.7436 23.9998L29.8052 19.9383C30.277 19.4665 30.277 18.687 29.7847 18.2152Z"
        fill="inherit"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-close-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
